import "styles/pages/products.scss"

import React, { useEffect, useState } from "react"
import {
  NumberParam,
  ArrayParam,
  useQueryParam,
  useQueryParams,
  StringParam,
} from "use-query-params"
import { includes, some } from "lodash"
import { graphql } from "gatsby"
import mapProductsFormAnalytics from "utils/mapProductsFormAnalytics"
import ProductsFiltersProvider from "context/products_filters"
import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import LayoutSwitch from "components/LayoutSwitch/LayoutSwitch"
import Pagination from "components/Pagination/Pagination"
import ProductTile from "components/ProductTile/ProductTile"
import ProductsFilters from "components/Products/ProductsFilters/ProductsFilters"
import ProductCartConfirmation from "components/Product/ProductCartConfirmation/ProductCartConfirmation"

const PRODUCTS_PER_PAGE = 15

const Products = ({ data }) => {
  const title = "Produkty"
  const [layout, setLayout] = useState("columns")
  const [cartConfirmation, setCartConfirmation] = useState(false)

  const archiveData = {
    layout,
    setLayout,
    cartConfirmation,
    setCartConfirmation,
  }

  const allProducts = data.allWpProduct.nodes

  const [query, setQuery] = useQueryParams({
    paProducents: ArrayParam,
    paKlasaProduktus: ArrayParam,
    paPrzekatnaEkranus: ArrayParam,
    paProcesors: ArrayParam,
    paPamiecRams: ArrayParam,
    paKartaGraficznas: ArrayParam,
    paSystemOperacyjnies: ArrayParam,
  })

  let _data = allProducts
  const [mainTitle] = useQueryParam("mainTitle", StringParam)
  if (!!mainTitle) {
    _data = _data.filter(product =>
      new RegExp(mainTitle, "i").test(product.name)
    )
  }
  const [priceMin] = useQueryParam("priceMin", NumberParam)
  if (!!priceMin) {
    _data = _data.filter(
      product =>
        product.price.replace("zł", "").replace(",", "").replace(".00", "") >=
        priceMin
    )
  }
  const [priceMax] = useQueryParam("priceMax", NumberParam)
  if (!!priceMax) {
    _data = _data.filter(
      product =>
        product.price.replace("zł", "").replace(",", "").replace(".00", "") <=
        priceMax
    )
  }
  const filterProductsByQueryFilters = (products, queryFilters) => {
    if (queryFilters.length > 0) {
      for (const key of queryFilters) {
        products = products.filter(product =>
          some(query[key], _key => {
            return includes(product?.[key]?.nodes?.[0]?.name, _key)
          })
        )
      }
      return products
    } else {
      return products
    }
  }
  const filtered_products = filterProductsByQueryFilters(
    _data,
    Object.keys(query)
      .map(key => (query?.[key]?.length > 0 ? key : null))
      .filter(Boolean)
  )

  // pagination
  const [skip, setSkip] = useQueryParam("skip", NumberParam)
  const _skip = skip || 0

  const _dataForPage = filtered_products.slice(_skip, _skip + PRODUCTS_PER_PAGE)
  const currentPage = _skip / PRODUCTS_PER_PAGE + 1
  const numPages = Math.max(
    1,
    Math.ceil(filtered_products.length / PRODUCTS_PER_PAGE)
  )

  const onPrevClick = () => {
    setSkip((currentPage - 2) * PRODUCTS_PER_PAGE)
  }
  const onNextClick = () => {
    setSkip(currentPage * PRODUCTS_PER_PAGE)
  }
  const onItemClick = page => {
    setSkip((page - 1) * PRODUCTS_PER_PAGE)
  }
  useEffect(() => {
    window.dataLayer?.push({ ecommerce: null })
    window.dataLayer.push({
      ecommerce: {
        currencyCode: "PLN",
        impressions: mapProductsFormAnalytics({
          products: _dataForPage,
        }),
      },
    })
  }, [_dataForPage])
  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />
      <ProductsFiltersProvider
        all_products={_data}
        filterProductsByQueryFilters={filterProductsByQueryFilters}
        query={query}
        setQuery={setQuery}
      >
        <section className="products">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3">
                <PageHeader title="Filtracja" className="page__header--pink" />
                <ProductsFilters />
              </div>

              <div className="col-xl-9">
                <PageHeader title={title} />

                <div className="products__filters products__filters--top">
                  <LayoutSwitch {...archiveData} />
                  {!!_dataForPage?.length && (
                    <Pagination
                      currentPage={currentPage}
                      numPages={numPages}
                      onPrevClick={onPrevClick}
                      onNextClick={onNextClick}
                      onItemClick={onItemClick}
                      {...archiveData}
                    />
                  )}
                </div>

                <div
                  className={`row row__products row__products--products row__products--products--${layout}`}
                >
                  {_dataForPage.length ? (
                    _dataForPage.map((item, index) => (
                      <div
                        className={
                          layout === "columns" ? "col-md-4" : "col-md-12"
                        }
                        key={index}
                      >
                        <ProductTile
                          className={layout === "rows" && "product-tile__rows"}
                          id={item.id}
                          databaseId={item.databaseId}
                          slug={item.slug}
                          title={item.name}
                          image={item.image}
                          price={item.price}
                          promoPrice={item.salePrice}
                          stockQuantity={item.stockQuantity}
                          setCartConfirmation={setCartConfirmation}
                          product_data={item}
                        />
                      </div>
                    ))
                  ) : (
                    <p className="row__products-empty-info">
                      Brak produktów w kategorii <strong>{title}</strong>.
                    </p>
                  )}
                </div>

                <div className="products__filters">
                  <div></div>
                  {!!_dataForPage?.length && (
                    <Pagination
                      currentPage={currentPage}
                      numPages={numPages}
                      onPrevClick={onPrevClick}
                      onNextClick={onNextClick}
                      onItemClick={onItemClick}
                      {...archiveData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </ProductsFiltersProvider>
      {cartConfirmation && <ProductCartConfirmation {...archiveData} />}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { slug: { nin: "private-category" } } }
        }
      }
    ) {
      nodes {
        id
        databaseId
        name
        slug
        image {
          sourceUrl
          localFile {
            publicURL
          }
        }
        ... on WpSimpleProduct {
          price
          salePrice
          stockQuantity
        }
        paProducents {
          nodes {
            name
          }
        }
        productCategories {
          nodes {
            name
          }
        }
        paKlasaProduktus {
          nodes {
            name
          }
        }
        paPrzekatnaEkranus {
          nodes {
            name
          }
        }
        paProcesors {
          nodes {
            name
          }
        }
        paPamiecRams {
          nodes {
            name
          }
        }
        paDyskTwardies {
          nodes {
            name
          }
        }
        paKartaGraficznas {
          nodes {
            name
          }
        }
        paSystemOperacyjnies {
          nodes {
            name
          }
        }
      }
    }
  }
`

export default Products
